import { render, staticRenderFns } from "./VerticalProducts.vue?vue&type=template&id=1c4e6500&"
import script from "./VerticalProducts.vue?vue&type=script&lang=js&"
export * from "./VerticalProducts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports